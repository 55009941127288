import "../css/app.scss";
import "vue-toastification/dist/index.css";
import "./bootstrap";
import env from "./env";
import { createSSRApp, h, DefineComponent } from "vue";
import { createHead } from "@vueuse/head";
import { createInertiaApp } from "@inertiajs/vue3";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import InstantSearch from "vue-instantsearch/vue3/es";
import DefaultLayoutFile from "./Layouts/Default.vue";
import { createI18n } from "vue-i18n";
import { fr } from "./locales/fr";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";
import { prepareTitle } from "./helpers/title";
import mitt from "mitt";

const i18n = createI18n({
    locale: "fr", // set locale
    fallbackLocale: "fr", // set fallback locale
    legacy: false,
    messages: {
        fr,
    },
});

const emitter = mitt();

const options: PluginOptions = {
    position: POSITION.BOTTOM_CENTER,
};

createInertiaApp({
    title: (title) => prepareTitle(title),
    resolve: async (name) => {
        if (!name) {
            throw new Error(
                `Erreur inertia de page inconnue: ${window.location.href}`,
            );
        }

        const pages = import.meta.glob<DefineComponent>("./Pages/**/*.vue", {
            eager: false,
        });

        let pageResolver = pages[`./Pages/${name}.vue`];

        if (!pageResolver) {
            throw new Error(`Page not found: ${name}`);
        }

        const page = await pageResolver(); // <-- ici
        page.default.layout = page.default.layout || DefaultLayoutFile;
        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(InstantSearch)
            .use(i18n)
            .use(Toast, options)
            .use(VueGoogleMaps, {
                load: {
                    key: env.VITE_GOOGLE_API_KEY,
                    libraries: "places",
                },
            });

        app.config.globalProperties.$emitter = emitter;

        // Define your global filters
        app.config.globalProperties.$filters = {
            number(value: number) {
                if (isNaN(value)) return value;
                return new Intl.NumberFormat("fr-FR").format(value);
            },
            currency(value: number, strict: boolean = true) {
                return (
                    new Intl.NumberFormat("fr-FR", {
                        minimumFractionDigits: strict ? 0 : 2,
                        maximumFractionDigits: strict ? 0 : 2,
                    }).format(value) + "€"
                );
            },
            date(value, format) {
                return dayjs(value)
                    .locale("fr")
                    .format(format ? format : "DD MMMM YYYY");
            },
        };

        Sentry.init({
            app,
            dsn: env.VITE_SENTRY_DSN_PUBLIC,
            environment: env.VITE_SENTRY_ENVIRONMENT,

            // This enables automatic instrumentation (highly recommended), but is not
            // necessary for purely manual usage
            // For better instrumentation, see https://github.com/getsentry/sentry-javascript/discussions/8528
            integrations: [new BrowserTracing()],

            sampleRate: parseFloat(env.VITE_SENTRY_SAMPLE_RATE || "0.1"),
            // To set a uniform sample rate
            tracesSampleRate: parseFloat(
                env.VITE_SENTRY_TRACES_SAMPLE_RATE || "1",
            ),

            enabled: env.VITE_SENTRY_ENABLED != "false",
        });

        const head = createHead();
        app.use(head);

        app.mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
